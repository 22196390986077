import "@fontsource/manrope/variable.css";
import "@fontsource/oswald/variable.css";
import "@fontsource/open-sans/variable.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/didact-gothic/400.css";
import React from "react";
import { RootLayout } from "./src/components/RootLayout";

export const wrapRootElement = ({ element }) => (
  <RootLayout>{element}</RootLayout>
);
